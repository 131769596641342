.ant-radio-checked .ant-radio-inner {
  border-color: rgb(118, 117, 118) !important ;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: rgb(118, 117, 118);
}

.ant-radio:hover .ant-radio-inner {
  border-color: rgb(118, 117, 118);
}

.ant-radio-wrapper span {
  color: rgb(118, 117, 118);
}

.table-row-light {
  background-color: #ffffff !important;
}
.table-row-dark {
  background-color: #EAECED;
}

thead[class*="ant-table-thead"] th {
  font-weight: bold;
  color: rgb(94, 95, 96);
}

thead[class*="ant-table-thead"] th:nth-child(even) {
  background-color: #f4f5f5 !important;
}

thead[class*="ant-table-thead"] th:nth-child(odd) {
  background-color: rgb(250, 250, 250) !important;
}

.ant-table.ant-table-small .ant-table-tbody > tr > td {
  cursor: pointer;
  padding: 5px 8px;
}
.ant-pagination-item-link{
  border-radius: 50% !important;
}

.ant-pagination-item{
  border: none !important;
  display: flex;
  align-items: center;
  justify-content: center;

}
.ant-pagination-item-active > a:hover {
  color:#fff
}

.ant-pagination-item:hover{
  background-color: #776aae ;
}
.ant-pagination-item-active{
  background-color: #8E84BB;
  border-radius: 50% !important;
}

.ant-pagination-item-active a {
  color: #fff;
}

.ant-pagination{
  display: flex;
}
.ant-pagination-item-active:focus-visible a{
  color: #fff !important;
}
.ant-pagination-item-active:hover a{
  color: #fff !important;
}

.ant-pagination-item:focus-visible a {
  color: #776aae;
}
.ant-pagination-item:hover a{
  color: #776aae;
}

.ant-pagination-prev:focus-visible .ant-pagination-item-link, .ant-pagination-next:focus-visible .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
  color: #776aae;
}

.ant-pagination-item{
  color: #484848;
}

.ant-card-head-title {
  color: #484848;
}
.ant-card {
  border-radius: 8px;
}
.ant-card-body {
 color: #776AAE;
}

.ant-collapse {
  border-radius: 16px;
  min-width: 285px;
  max-width: 285px;
}

.ant-collapse-content{
  color: #484848;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #776AAE;
  border-right-width: 1px !important;
}
.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
  border-color: #776AAE;
  box-shadow: 0 0 0 2px #776AAE7e;
}
.ant-input-affix-wrapper {
  border-radius: 8px;
  border-color: #484848;
}
.anticon-search svg > path {
  stroke: #484848;
}

.ant-input-affix-wrapper input::placeholder {
  color: #484848;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}
.anticon-search svg > path{
  fill: #484848;
}
.ant-input-affix-wrapper input {
  background-color: transparent;
  padding-left: 24px;
}
.ant-input-affix-wrapper {
  border-radius: 32px;
  background-color: transparent;
  border-color: #484848;

  input::placeholder {
    color: #484848;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
  }

}
.ant-input-affix-wrapper:hover {
  border-color: #776aae;
}

.ant-table-wrapper > td {
    border: none;
  }
  .ant-table-cell > span {
    color: #484848 !important;
    font-weight: 500 !important;
  }
.ant-table-wrapper {
  .ant-table-thead {
    .ant-table-cell:last-of-type {
      @media (min-width: 767px) {
        text-align: center;
      }
    }
    .ant-table-cell {
      background-color: transparent !important;
      border: none;
      font-size: 18px;
      span {
        color: #bfbfbf !important;
      }
    }
  }
  .ant-table-row {
    .ant-table-cell {
      padding: 20px 0px;
      border: none;
      > span {
        color: #484848 !important;
        font-weight: 500 !important;
      }
    }
  }
  .ant-table-cell {
    padding: 20px 0px;
    border: none;
    > span {
      color: #484848 !important;
      font-weight: 500 !important;
    }
  }
  .table-row-dark {
    .ant-table-cell:first-of-type {
      border-top-left-radius: 32px;
      border-bottom-left-radius: 32px;
    }
    .ant-table-cell:last-of-type {
      border-top-right-radius: 32px;
      border-bottom-right-radius: 32px;
    }
  }
  .table-row-light {
    .ant-table-cell:first-of-type {
      border-top-left-radius: 32px;
      border-bottom-left-radius: 32px;
    }
    .ant-table-cell:last-of-type {
      border-top-right-radius: 32px;
      border-bottom-right-radius: 32px;
    }
  }
  .ant-table-tbody > .ant-table-row:hover td {
    background-color: #776aae !important;
    color: #eaeced;
  }
  .ant-table-tbody > .ant-table-row:hover td {
    span {
      color: #eaeced !important;
    }
    div > button > svg > path {
      stroke: #eaeced;
    }
  }
  .ant-table {
    background: transparent;
  }
  .ant-select-selector {
    background-color: transparent;
    border: none !important;
    span {
      color: #484848;
      font-family: 'Montserrat';
    }
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #776aae9e !important;
  }
  .ant-select-focused:not(.ant-select-disabled):hover .ant-select-selector,
  .ant-select-focused:not(.ant-select-disabled) .ant-select-selector {
    box-shadow: none;
  }
}
.ant-select-arrow span > svg > path{
  fill: #484848;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: #B9B3D6;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #776aae;
  border-color: #776aae;
}
.ant-pagination {
  align-items: center;
}

.ant-modal-content{
  border-radius: 16px;
}
.ant-modal-footer {
  border: none;
}
.ant-modal-body {
  display: flex;
  justify-content: center;
}
.ant-modal-footer{
  display: flex;
  justify-content: center;
  padding-bottom: 25px;
  .ant-btn:not(.ant-btn-primary){
    background-color: #F15B51;
    color: #484848;
    border-radius: 8px;
  }
  .ant-btn-primary {
    background-color: #776aae;
    border-color: #776aae;
    border-radius: 8px;
  }
  .ant-btn-primary:hover {
    background-color: #776aae7e;
    border-color: #776aae;
  }
  .ant-btn:not(.ant-btn-primary):hover, .ant-btn:focus {
    color: #fff;
    background: #F69E9E;
    border-color: #F15B51;
  }
}

.ant-checkbox-checked::after {
 border: none;
}

.ant-pagination.mini .ant-pagination-item {
  display: flex;
}

.ant-collapse-content > .ant-collapse-content-box {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
